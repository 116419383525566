import React from 'react'
import { Link, graphql } from 'gatsby'
import SEO from '../components/seo'
import Layout from '../components/layout'
import BackButton from '../components/backButton'
import CaseStudyCard from '../components/caseStudyCard'
import LearnMoreButton from '../components/learnMoreButton'
import * as styles from './templates.module.css'
import classNames from 'classnames'
import Callout from '../components/callout'
import { getTextCopy } from '../helpers'

const Service = ({ data }) => {
  const {
    slug,
    title,
    excerpt,
    image,
    relatedWork,
    relatedServices,
    serviceContent,
  } = data.service
  const { seoImage, pageSlug, content, callout } = data.servicePage
  const { servicesPageSlug } = data.servicesPage
  const { caseStudyPageSlug } = data.caseStudyPage

  const fullSlug = pageSlug.replace(`{{slug}}`, slug)

  return (
    <>
      <SEO
        title={title}
        description={excerpt}
        imageUrl={seoImage.file.url}
        urlPath={fullSlug}
      />
      <Layout callout={callout && <Callout data={callout} />}>
        <div
          className={
            relatedWork ? styles.servicePageWrapper : styles.pageWrapper
          }
        >
          <Link to={servicesPageSlug}>
            <BackButton>
              {getTextCopy(content, 'page.service.backButton')}
            </BackButton>
          </Link>
          <div className={styles.servicePageGrid}>
            <div
              className={classNames([
                styles.serviceContent,
                relatedWork && styles.serviceGridColumn,
              ])}
            >
              {image && (
                <img
                  src={image.file.url}
                  className={styles.image}
                  alt={title}
                />
              )}
              <h1 className={styles.serviceTitle}>{title}</h1>
              <div
                className={styles.serviceHtml}
                dangerouslySetInnerHTML={{
                  __html: serviceContent.childMarkdownRemark.html,
                }}
              />
            </div>
            {relatedWork && (
              <div className={styles.sidebar}>
                <h4>{getTextCopy(content, 'page.service.relatedWorkHeader')}</h4>
                <div className={styles.sidebarFlex}>
                  {relatedWork.map(work => {
                    return (
                      <CaseStudyCard
                        tags={work.tags}
                        key={work.title}
                        title={work.title}
                        description={work.excerpt}
                        linkTo={caseStudyPageSlug.replace(
                          `{{slug}}`,
                          work.slug
                        )}
                        image={work.image.file.url}
                        sidebar
                      />
                    )
                  })}
                </div>
              </div>
            )}
          </div>
          {relatedServices && (
            <div className={styles.relatedServicesWrapper}>
              <h4>{getTextCopy(content, 'page.service.relatedServicesHeader')}</h4>
              <div className={styles.relatedServicesGrid}>
                {relatedServices &&
                  relatedServices.map(service => {
                    return (
                      <Link
                        className={styles.relatedService}
                        key={service.id}
                        to={pageSlug.replace(`{{slug}}`, service.slug)}
                      >
                        <div>
                          <h3 className={styles.relatedServiceTitle}>
                            {service.title}
                          </h3>
                          <p className={styles.relatedServiceExcerpt}>
                            {service.excerpt}
                          </p>
                          <LearnMoreButton className={styles.learnMoreButton} />
                        </div>
                      </Link>
                    )
                  })}
              </div>
            </div>
          )}
        </div>
      </Layout>
    </>
  )
}

export default Service

export const pageQuery = graphql`
  query serviceQuery($slug: String!) {
    service: contentfulService(slug: { eq: $slug }) {
      slug
      title
      excerpt
      image {
        file {
          url
        }
      }
      relatedWork {
        id
        title
        excerpt
        image {
          file {
            url
          }
        }
        tags {
          id
          title
        }
        slug
      }
      relatedServices {
        id
        title
        excerpt
        slug
      }
      serviceContent: content {
        childMarkdownRemark {
          html
        }
      }
    }
    servicePage: contentfulPage(name: { eq: "page.service" }) {
      ...pageFields
    }
    servicesPage: contentfulPage(name: { eq: "page.services" }) {
      servicesPageSlug: slug
    }
    caseStudyPage: contentfulPage(name: { eq: "page.caseStudy" }) {
      caseStudyPageSlug: slug
    }
  }
`
